import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

interface cookiesState {
  showDeactivateCookies: boolean
}

class cookies extends React.Component<{}, cookiesState> {
  constructor(props) {
    super(props)
    this.state = {
      showDeactivateCookies: false,
    }
  }

  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')

    const showFeatures = (e) => {
      // console.log(e.target.value)
      if (e.target.value === 'Cookies') {
        this.setState({
          ...this.state,
          showDeactivateCookies: true,
        })
      }
    }

    const removeCookieInstruction = (
      <div>
        <h4 id="Cookies" className="pt-2">
          Chrome
        </h4>
        <p>
          From the Chrome menu in the top right corner of the browser,
          select&nbsp;<strong>Settings</strong>.
        </p>
        <p>
          At the bottom of the page, click&nbsp;
          <strong>Show advanced settings...</strong>
        </p>
        <p>
          Under Privacy, select <strong>Content settings...</strong>
        </p>
        <ol>
          <li>
            To manage cookie settings, check or uncheck the options under
            &ldquo;Cookies&rdquo;.
          </li>
          <li>
            To view or remove individual cookies, click
            <strong> All cookies and site data...</strong> and hover the mouse
            over the entry. Select the X that appears next to the cookie to
            remove it.
          </li>
          <li>
            To delete all cookies, click
            <strong> All cookies and site data... </strong> and select{' '}
            <strong> Remove all.</strong>
          </li>
        </ol>
        <h4>Firefox</h4>
        <p>
          From the <strong> Tools </strong> menu, select
          <strong> Options</strong>. If the menu bar is hidden, press
          <strong> Alt </strong> to make it visible.
        </p>
        <p>
          At the top of the window that appears, click&nbsp;
          <strong>Privacy</strong>.{' '}
        </p>
        <p>
          To manage cookie settings, from the drop-down menu under
          &ldquo;History&rdquo;, select
          <strong> Use custom settings for history</strong>. Enable or disable
          the settings by checking or unchecking the boxes next to each setting:
        </p>
        <p className="pl-3 mb-2">
          1. To allow sites to set cookies on your computer, select&nbsp;
          <strong>Accept cookies from sites</strong>. To specify which sites are
          always or never allowed to use cookies, click{' '}
          <strong> Exceptions</strong>.
        </p>
        <p className="pl-3 mb-2">
          2. To accept third-party cookies, check
          <strong> Accept third-party cookies</strong>. In the &ldquo;Keep
          until:&rdquo; drop-down menu, select the time period you wish to keep
          cookies on your computer.
        </p>
        <p className="pl-3 mb-2">
          3. To specify how the browser should clear the private data it stores,
          check Clear history when Firefox closes. Click{' '}
          <strong> Settings...</strong> Check the items to be cleared when you
          close Firefox.
        </p>
        <p className="pl-3 mb-2">
          4. To view or remove individual cookies, click
          <strong> remove individual cookies</strong>.
        </p>
        <p className="pl-3 mb-2">
          5. To remove all cookies, from the History menu, select{' '}
          <strong> clear your recent history. </strong>
          Click the arrow next to &ldquo;Details&rdquo; to expand the menu,
          check the items you want to clear, and then click
          <strong> ClearNow</strong>.
        </p>
        <h4>Internet Explorer</h4>
        <p>
          From the <strong> Tools </strong> menu, or the
          <strong> Tools </strong> drop-down at the upper right, select Internet
          Options. If the menu bar is hidden, press Alt to make it visible.
        </p>
        <ol>
          <li>
            To manage cookie settings, select the
            <strong> Privacy </strong> tab and click
            <strong> Advanced</strong>. To override the automatic cookie
            settings, click
            <strong> Override automatic cookie handling </strong> and then make
            your selections using the radio buttons.
          </li>
          <li>
            To view or remove individual cookies, select the
            <strong> General </strong> tab. Under &ldquo;Browsing
            history&rdquo;, click <strong>Settings</strong>. Select either
            <strong> View objects </strong> or
            <strong> View files.</strong>
          </li>
          <li>
            To delete all cookies, under &ldquo;Browsing history&rdquo;, click
            <strong> Delete...</strong> Check Cookies and then click Delete.
          </li>
        </ol>
        <h4>Safari</h4>
        <p>
          Note: To determine the version of Safari you&apos;re using, from the{' '}
          <strong> Safari </strong> menu, select
          <strong> About Safari</strong>.
        </p>
        <h6>Safari 5.1 and later</h6>
        <p>
          In Safari, from the <strong> Safari </strong> menu, select{' '}
          <strong> Preferences...</strong>
        </p>
        <p>
          In the Safari preferences window, click
          <strong> Privacy</strong>.
        </p>
        <ol>
          <li>
            To manage cookie settings, next to &ldquo;Block cookies&rdquo;,
            select from <strong> third parties</strong> and advertisers,{' '}
            <strong>Always</strong>, or
            <strong> Never</strong>.
          </li>
          <li>
            To view or remove individual cookies, click
            <strong> Details...</strong> Select the cookie to delete and click{' '}
            <strong> Remove</strong>.
          </li>
          <li>
            To delete all cookies, select
            <strong> Remove All Website Data... </strong> In the window that
            appears, select <strong> Remove Now</strong>.
          </li>
        </ol>
        <h6>Safari 5.0.x and earlier</h6>
        <p>
          In Safari, from the <strong> Safari </strong> menu, select{' '}
          <strong> Preferences...</strong>
        </p>
        <p>
          In the Safari preferences window, click
          <strong> Security</strong>.
        </p>
        <ol>
          <li>
            To manage cookie settings, next to &ldquo;Accept Cookies:&rdquo;,
            select <strong> Always </strong> or
            <strong> Only from sites you navigate to </strong>. To disable
            cookies, select <strong> Never</strong>.
          </li>
          <li>
            To view and delete individual cookies, click
            <strong> Show Cookies</strong>. In the sheet that drops down, you
            can browse the list of cookies on your computer.
          </li>
          <li>
            To delete all cookies, click
            <strong>Show Cookies</strong>. In the window that appears, select{' '}
            <strong> Remove All</strong>.
          </li>
        </ol>
      </div>
    )

    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        {/* <!-- ========== PRIVACY POLICY SECTION ========== --> */}
        <section className="mt-5 pt-5 pb-5 px-2">
          <div className="container-1">
            <div className="row">
              <div className="col-md-12">
                <h1 className="underline h3-like text-uppercase">Cookies</h1>
                <p>
                  This policy should be read in conjunction with our{' '}
                  <a href="/legals/terms-and-conditions/">Terms of Use</a>.
                </p>
                <h4>INFORMATION ABOUT THE USE OF COOKIES</h4>
                <p>
                  Our website uses cookies to distinguish you from other users
                  of our website. This helps us to provide you with a good
                  experience when you browse our website and also allows us to
                  improve our site. By continuing to browse the site, you are
                  agreeing to the use of cookies.
                </p>
                <p>
                  A cookie is a small file of letters and numbers that we store
                  on your browser or the hard drive of your computer if you
                  agree. Cookies contain information that is transferred to your
                  computer&apos;s hard drive.
                </p>
                <p>We use the following cookies:</p>
                <p className="pl-3">
                  <b>Strictly necessary cookies</b>. These are cookies that are
                  required for the operation of our website. They include, for
                  example, cookies that enable you to log into any secure areas
                  of our website.
                </p>
                <p className="pl-3">
                  <b>Analytical cookies</b>. They allow us to recognize and
                  count the number of visitors and to see how visitors move
                  around our website when they are using it. This helps us to
                  improve the way our website works, for example, by ensuring
                  that users are finding what they are looking for easily.
                </p>
                <p className="pl-3">
                  <b>Functionality cookies</b>. These are used to recognize you
                  when you return to our website. This enables us to personalise
                  our content for you, greet you by name and remember your
                  preferences (for example, your choice of language or region).
                </p>
                <p className="pl-3">
                  <b>Targeting cookies</b>. These cookies record your visit to
                  our website, the pages you have visited and the links you have
                  followed. We will use this information to make our website and
                  the advertising displayed on it more relevant to your
                  interests. We may also share this information with third
                  parties for this purpose.
                </p>
                <h4>HOW TO MANAGE COOKIES</h4>
                <p>
                  You can block cookies by activating the setting on your
                  browser that allows you to refuse the setting of all or some
                  cookies. However, if you use your browser settings to block
                  all cookies (including essential cookies) you may not be able
                  to access all, or parts of our site. Most web browsers allow
                  some control of most cookies through browser settings. Before
                  you change your cookie settings, it is important to note that
                  blocking all cookies may prevent you from entering many
                  websites.
                </p>
                <button
                  value="Cookies"
                  style={{ color: '#5c89bf' }}
                  onClick={(e) => {
                    showFeatures(e)
                  }}
                  className="border-0 outline-0 bg-white fw-600 p-0"
                >
                  How to remove Cookie tracking {'\u2228'}
                </button>

                <section>
                  {!this.state.showDeactivateCookies ? (
                    <div></div>
                  ) : (
                    removeCookieInstruction
                  )}
                </section>
              </div>
            </div>
            <hr className="pt-2" />
            <p className="pt-2">Last update: 1 Jan 2020</p>
          </div>
        </section>
      </Layout>
    )
  }
}
export default cookies

export const pageQuery = graphql`
  query Legals_CookiesSEO {
    allContentfulPages(
      filter: { pageReference: { pageIdentifier: { eq: "Legals_Cookies" } } }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
